<template>
  <div class="app-container">
    <div v-show="!edit.show">
      <div class="head-container">
        <el-date-picker v-model="query.dateRange" type="daterange" :clearable="false" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
        <el-select v-model="query.status" filterable clearable placeholder="状态" class="filter-item" style="width: 130px" @change="toQuery">
          <el-option v-for="(item, key) in status" :key="key" :label="item.label" :value="key" />
        </el-select>
        <el-input v-model="query.name" clearable placeholder="根据活动名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-input v-model="query.createBy" clearable placeholder="创建人搜索" style="width: 130px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="doAdd" v-permission="['MARKET_MANAGE','MARKET_MANAGE_EDIT']">新增整单优惠活动</el-button>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>
      <el-table v-loading="loading" row-key="id" :data="data">
        <el-table-column prop="code" label="流水号" width="120" />
        <el-table-column prop="name" label="活动名称" :show-overflow-tooltip="true" min-width="240" />
        <el-table-column label="活动方式" min-width="120">
          <template slot-scope="scope">
            <span>{{methods[scope.row.method]}}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动商品范围" min-width="120">
          <template slot-scope="scope">
            <span>{{goodsRanges[scope.row.goodsRange]}}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动开始时间" width="140" :formatter="r => {return new Date(r.beginTime).format();}" />
        <el-table-column label="活动结束时间" width="140" :formatter="r => {return new Date(r.endTime).format();}" />
        <el-table-column label="创建人" width="100" prop="createBy" />
        <el-table-column label="创建时间" width="140" :formatter="r => {return new Date(r.createAt).format();}" />
        <el-table-column label="状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="150" fixed="right">
          <div class="row-commands" slot-scope="scope">
            <template v-if="scope.row.status === 'init'">
              <!-- <el-button size="mini" type="text" @click="doEdit(scope.row)">编辑</el-button> -->
              <el-button size="mini" type="text" @click="doEdit(scope.row)" v-if="checkPermission(['MARKET_MANAGE','MARKET_MANAGE_EDIT'])">编辑</el-button>
              <el-button size="mini" type="text" @click="doView(scope.row)" v-else>查看</el-button>
              <el-button size="mini" type="text" @click="start(scope.row)" v-permission="['MARKET_MANAGE','MARKET_MANAGE_START']">审核</el-button>
              <el-popover :ref="scope.row.id" placement="top" width="180" v-permission="['MARKET_MANAGE','MARKET_MANAGE_DEL']">
                <p>确定删除本条数据吗？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                  <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
                </div>
                <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
              </el-popover>
            </template>
            <template v-else>
              <el-button size="mini" type="text" @click="doEdit(scope.row)">查看</el-button>
              <el-button size="mini" type="text" @click="doCopy(scope.row)" v-if="checkPermission(['MARKET_MANAGE','MARKET_MANAGE_EDIT'])">复制</el-button>
              <el-button size="mini" type="text" @click="start(scope.row)" v-if="scope.row.status === 'stop' && checkPermission(['MARKET_MANAGE','MARKET_MANAGE_START'])">审核</el-button>
              <el-button size="mini" type="text" @click="stop(scope.row)" v-else-if="scope.row.status === 'start' && checkPermission(['MARKET_MANAGE','MARKET_MANAGE_START'])">停用</el-button>
            </template>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    </div>
    <editor :id.sync="edit.current" :is-copy.sync="edit.copy" v-if="edit.show" @cancel="handleEditCancel" :readonly="edit.readonly" />
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { order } from "@/api/marketing";
import editor from "./editor";

export default {
  components: { editor },
  mixins: [initData],
  data() {
    let now = new Date();
    return {
      delLoading: false,
      loding: false,
      query: {
        status: null,
        name: null,
        dateRange: [
          new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0).getTime(),
          new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            23,
            59,
            59
          ).getTime(),
        ],
      },
      edit: {
        show: false,
        current: null,
        readonly: false,
        copy: false,
      },
      methods: {
        discount: "打折",
        save: "减价",
        price: "一口价",
        gift: " 赠商品",
      },
      goodsRanges: {
        all: "全部商品",
        part: "部分商品",
      },
      status: {
        init: { label: "待审核", type: "info" },
        start: { label: "已审核", type: "success" },
        stop: { label: "已反审", type: "danger" },
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    handleEditCancel() {
      this.edit.show = false;
      this.edit.copy = false;
      this.edit.readonly = false;
    },
    beforeInit() {
      this.url = "api/wholeOrder";

      this.params = Object.assign(
        {
          page: this.page,
          size: this.size,
          sort: "createAt,desc",
          createAtBeg: this.query.dateRange[0],
          createAtEnd: this.query.dateRange[1],
        },
        this.query
      );
      return true;
    },
    formatSpecs(specs) {
      try {
        var s = JSON.parse(specs || "{}");
        var res = "";
        s &&
          s.forEach((item) => {
            res += item.value;
          });
        return res;
      } catch (e) {}
    },
    doAdd() {
      this.edit.current = false;
      this.edit.show = true;
    },
    doEdit(data) {
      this.edit.current = data.id;
      this.edit.show = true;
    },
    doView(data) {
      this.edit.current = data.id;
      this.edit.show = true;
    },
    doCopy(data) {
      this.edit.current = data.id;
      this.edit.show = true;
      this.edit.copy = true;
      // this.$confirm("您确定要复制该商品促销活动吗？", "操作确认", {
      //   type: "warning",
      //   dangerouslyUseHTMLString: true,
      // }).then((_) => {
      //   order.copy(data.id).then((res) => {
      //     this.init();
      //     this.$message({
      //       type: "success",
      //       message: "复制成功!",
      //     });
      //   });
      // });
    },
    start(data) {
      this.$confirm(
        data.status === "init"
          ? "审核整单优惠活动后，不能再对该活动内容进行编辑。<br />您确定要审核该整单优惠活动吗？"
          : "您确定要审核该整单优惠活动吗？",
        "操作确认",
        { type: "warning", dangerouslyUseHTMLString: true }
      ).then((_) => {
        order.start(data.id).then((res) => {
          this.init();
          this.$message({
            type: "success",
            message: "审核成功!",
          });
        });
      });
    },
    stop(data) {
      this.$confirm("您确定要反审该整单优惠活动吗？", "操作确认", {
        type: "warning",
      }).then((_) => {
        order.stop(data.id).then((res) => {
          this.init();
          this.$message({
            type: "success",
            message: "反审成功!",
          });
        });
      });
    },
    subDelete(id) {
      this.delLoading = true;
      order
        .del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
        });
    },
  },
};
</script>